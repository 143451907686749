import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "@apollo/client/link/http";
import { isServer } from "solid-js/web";
import { serverOnly$ } from "vite-env-only/macros";

if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const uri = `${import.meta.env.VITE_API_ENDPOINT}/graphql`;
const httpLink = new HttpLink({
  uri,
  fetchOptions: { credentials: "include" },
});

const cookie = await (async (): Promise<string | undefined> => {
  if (!import.meta.env.SSR && !isServer) {
    return;
  }

  const response = await fetch(uri, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "apollo-require-preflight": "true",
    },
    body: JSON.stringify({
      query: `#graphql
        mutation Login($username: String!, $password: String!) {
          login(username: $username, password: $password) {
            id
          }
        }
      `,
      variables: serverOnly$({
        username: "\u007F",
        password: import.meta.env.VITE_APP_INTERNAL_KEY,
      }),
    }),
  });
  return response.headers
    .getSetCookie()
    .map((cookie) => cookie.split(";")[0])
    .join("; ");
})();

const authLink = setContext((_, { headers }) => ({
  headers: { ...headers, cookie },
}));

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      UserProfile: {
        keyFields: ["slug"],
      },
    },
  }),
});
